;(() => {
  const audioUtils = require('./audioUtils') // For encoding audio data as PCM
  const crypto = require('crypto') // To sign our pre-signed URL
  const v4 = require('./aws-signature-v4') // To generate our pre-signed URL
  const marshaller = require('@aws-sdk/eventstream-marshaller') // For converting binary event stream messages to and from JSON
  const util_utf8_node = require('@aws-sdk/util-utf8-node') // Utilities for encoding and decoding UTF8
  const mic = require('microphone-stream') // Collect microphone input as a stream of raw bytes
  const { Subject } = require('rxjs')

  // Converter between binary event streams messages and JSON.
  const eventStreamMarshaller = new marshaller.EventStreamMarshaller(
    util_utf8_node.toUtf8,
    util_utf8_node.fromUtf8
  )

  defineGlobals()

  const languageCode = 'en-US'
  const sampleRate = 44100
  let inputSampleRate
  let socket
  let micStream
  let userAudioMediaStream

  function getCredentials() {
    return window.awsTranscribe.credentials
  }

  function defineGlobals() {
    window.awsTranscribe = {
      credentials: {
        aws: {
          region: '',
          credentials: {
            accessKeyId: '',
            secretAccessKey: '',
            sessionToken: ''
          }
        }
      },
      subscribe(cb) {
        startTranscribing()

        return window.awsTranscribe.subject.subscribe((transcription) =>
          cb(transcription)
        )
      },
      unsubscribe() {
        closeSocket()
      }
    }
    window.awsTranscribe.subject = new Subject()
  }

  function startTranscribing() {
    window.navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        userAudioMediaStream = stream
        streamAudioToWebSocket(stream)
      })
      .catch((error) => {
        console.error('error:', error)
        closeSocket()
      })
  }

  function streamAudioToWebSocket(userMediaStream) {
    micStream = new mic()
    micStream.on('format', (data) => {
      inputSampleRate = data.sampleRate
    })
    micStream.setStream(userMediaStream)

    /**
     * Pre-signed URLs are a way to authenticate a request (or WebSocket
     * connection, in this case) via Query Parameters. Learn more:
     * https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
     */
    socket = new WebSocket(createPresignedUrl())
    socket.binaryType = 'arraybuffer'

    /**
     * When we get audio data from the mic, send it to the WebSocket if
     * possible.
     */
    socket.onopen = () => {
      micStream.on('data', (rawAudioChunk) => {
        /**
         * The audio stream is raw audio bytes. Transcribe expects PCM with
         * additional metadata, encoded as binary.
         */
        const binary = convertAudioToBinaryMessage(rawAudioChunk)

        if (socket.readyState === socket.OPEN) socket.send(binary)
      })
    }

    // Handle messages, errors, and close events.
    wireSocketEvents()
  }

  function wireSocketEvents() {
    socket.onmessage = (message) => {
      // Convert the binary event stream message to JSON.
      const messageWrapper = eventStreamMarshaller.unmarshall(
        Buffer(message.data)
      )
      const messageBody = JSON.parse(
        String.fromCharCode.apply(String, messageWrapper.body)
      )
      if (messageWrapper.headers[':message-type'].value === 'event') {
        handleEventStreamMessage(messageBody)
      } else {
        console.error(messageBody.Message)
        micStream.stop()
      }
    }

    socket.onerror = () => {
      console.error('WebSocket connection error. Try again.')
      closeSocket()
    }
  }

  function handleEventStreamMessage(messageJson) {
    window.awsTranscribe.subject.next(messageJson.Transcript.Results)
  }

  function stopUserAudioMediaStream() {
    userAudioMediaStream.getTracks().forEach((track) => track.stop())
  }

  function closeSocket() {
    if (socket.readyState === socket.OPEN) {
      micStream.stop()

      /**
       * Send an empty frame so that Transcribe initiates a closure of the
       * `WebSocket` after submitting all transcripts.
       */
      const emptyMessage = getAudioEventMessage(Buffer.from(new Buffer([])))
      const emptyBuffer = eventStreamMarshaller.marshall(emptyMessage)
      socket.send(emptyBuffer)
      socket.close()
      stopUserAudioMediaStream()
    }
  }

  function convertAudioToBinaryMessage(audioChunk) {
    let raw = mic.toRaw(audioChunk)

    if (raw == null) return

    // downsample and convert the raw audio bytes to PCM
    const downsampledBuffer = audioUtils.downsampleBuffer(
      raw,
      inputSampleRate,
      sampleRate
    )
    const pcmEncodedBuffer = audioUtils.pcmEncode(downsampledBuffer)

    // Add the right JSON headers and structure to the message.
    const audioEventMessage = getAudioEventMessage(
      Buffer.from(pcmEncodedBuffer)
    )

    // Convert the JSON object + headers into a binary event stream message.
    const binary = eventStreamMarshaller.marshall(audioEventMessage)

    return binary
  }

  // Wrap the audio data in a JSON envelope
  function getAudioEventMessage(buffer) {
    return {
      headers: {
        ':message-type': {
          type: 'string',
          value: 'event'
        },
        ':event-type': {
          type: 'string',
          value: 'AudioEvent'
        }
      },
      body: buffer
    }
  }

  function createPresignedUrl() {
    const credentials = getCredentials()
    const presignedURLEndpoint = `transcribestreaming.${credentials.aws.region}.amazonaws.com:8443`

    // Get a pre-authenticated URL that we can use to establish our WebSocket.
    return v4.createPresignedURL(
      'GET',
      presignedURLEndpoint,
      '/stream-transcription-websocket',
      'transcribe',
      crypto.createHash('sha256').update('', 'utf8').digest('hex'),
      {
        key: credentials.aws.credentials.accessKeyId,
        secret: credentials.aws.credentials.secretAccessKey,
        sessionToken: credentials.aws.credentials.sessionToken,
        protocol: 'wss',
        expires: 7,
        region: credentials.aws.region,
        query:
          'language-code=' +
          languageCode +
          '&media-encoding=pcm&sample-rate=' +
          sampleRate
      }
    )
  }
})()
